import * as React from 'react';
import {
  Spinner,
  Stack,
  Card,
  Badge,
  TextField,
  ButtonGroup,
  Button,
  Icon,
  TextStyle,
  DisplayText,
} from '@shopify/polaris';

const queryParams = window.location.search;
const shop = new URLSearchParams(queryParams).get('shop');

const IncidentsList = props => {
  if (props.incidents.length > 0) {
    return (
      <Card title='Incidents' sectioned>
        <DisplayText size='medium'>
          {props.incidents.length} total outstanding incidents
        </DisplayText>
        {props.incidents.map((incident, index) => (
          <Incident
            key={index}
            incident={incident}
            createIgnore={props.createIgnore}
            deleteIncident={props.deleteIncident}
          />
        ))}
      </Card>
    );
  } else {
    return (
      <Card title='Incidents' sectioned>
        No incidents to report at this time.
      </Card>
    );
  }
};

const Incident = props => {
  return (
    <Card.Section>
      <Stack vertical>
        <Stack.Item fill>
          <TextField
            helpText={`${props.incident.SourceType || 'Unknown'}${
              props.incident.SourceTitle !== null
                ? ` - ${props.incident.SourceTitle}`
                : ''
            }`}
            multiline
            readOnly
            value={props.incident.Target}
          />
        </Stack.Item>
        <Stack.Item>
          <Stack alignment='center'>
            <Stack.Item fill>
              <Badge>{props.incident.StatusCode}</Badge>
              <TextStyle variation='subdued'>
                {props.incident.Message}
              </TextStyle>
            </Stack.Item>
            <Stack.Item>
              <ButtonGroup>
                <Button primary external url={props.incident.Target}>
                  Link
                </Button>
                <Button external url={props.incident.Source}>
                  Site
                </Button>
                <AdminLinkButton adminLink={props.incident.AdminLink} />
                <Button
                  destructive
                  onClick={() => {
                    props.createIgnore(props.incident.Target);
                    props.deleteIncident(props.incident.RowKey);
                  }}
                >
                  Ignore
                </Button>
                <Button
                  onClick={() => props.deleteIncident(props.incident.RowKey)}
                  destructive
                >
                  <Icon source='delete' />
                </Button>
              </ButtonGroup>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </Card.Section>
  );
};

const AdminLinkButton = props => {
  if (props.adminLink === undefined || props.adminLink === null) {
    return null;
  } else {
    return (
      <Button external url={props.adminLink}>
        Admin
      </Button>
    );
  }
};

export default class Dashboard extends React.Component {
  state = {
    isLoading: false,
    incidents: [],
  };

  componentDidMount() {
    this.getIncidents();
  }

  getIncidents = () => {
    this.setState({ isLoading: true });
    fetch(`/api/incidents${queryParams}`)
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        console.log(data);
        this.setState({
          incidents: data,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        //console.log(error);
        try {
          error.text().then(errorMessage => {
            //console.log(errorMessage);
            if (errorMessage.length > 0) {
              alert(errorMessage);
            }
          });
        } catch {}
      });
  };

  createIgnore = url => {
    let ignore = {};
    ignore.PartitionKey = shop;
    ignore.RowKey = url;

    fetch(`/api/ignores${queryParams}`, {
      method: 'POST',
      body: JSON.stringify(ignore),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        console.log(response);
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          this.getIncidents();
        }
      })
      .catch(error => {
        //console.log(error);
        try {
          error.text().then(errorMessage => {
            //console.log(errorMessage);
            if (errorMessage.length > 0) {
              alert(errorMessage);
            }
          });
        } catch {}
        this.setState({ isLoading: false });
      });
  };

  deleteIncident = rowKey => {
    this.setState({ isLoading: true });

    fetch(`/api/incidents/${rowKey}${queryParams}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          this.getIncidents();
        }
      })
      .catch(error => {
        console.log(error);
        try {
          error.text().then(errorMessage => {
            console.log(errorMessage);
            if (errorMessage.length > 0) {
              alert(errorMessage);
            }
          });
        } catch {}
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, incidents } = this.state;

    if (isLoading) {
      return (
        <Stack distribution='center'>
          <Spinner color='teal' />
        </Stack>
      );
    } else {
      return (
        <IncidentsList
          incidents={incidents}
          createIgnore={this.createIgnore}
          deleteIncident={this.deleteIncident}
        />
      );
    }
  }
}
