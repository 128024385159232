import React from 'react';
import ReactDOM from 'react-dom';
import '@shopify/polaris/styles.css';
import App from './App';
import { AppProvider } from '@shopify/polaris';

const queryParams = window.location.search;
const shop = new URLSearchParams(queryParams).get('shop');
const shopOrigin = shop;
const apiKey = '6a4392bcc09176f30add28892e152b64';

ReactDOM.render(
  <AppProvider apiKey={apiKey} shopOrigin={shopOrigin} forceRedirect={false}>
    <App />
  </AppProvider>,
  document.getElementById('root'),
);
