import * as React from 'react';
import { Tabs } from '@shopify/polaris';

const tabs = [
  {
    id: 'main',
    content: 'Dashboard',
    accessibilityLabel: 'Dashboard',
    panelID: 'dashboard-content',
  },
  {
    id: 'customurls',
    content: 'Custom URLs',
    accessibilityLabel: 'Custom URLs',
    panelID: 'customurls-content',
  },
  {
    id: 'ignores',
    content: 'Ignored URLs',
    accessibilityLabel: 'Ignored URLs',
    panelID: 'ignores-content',
  },
  {
    id: 'settings',
    content: 'Settings',
    accessibilityLabel: 'Settings',
    panelID: 'settings-content',
  },
];

const pageToTabNum = page => {
  if (page === 'main') {
    return 0;
  } else if (page === 'customurls') {
    return 1;
  } else if (page === 'ignores') {
    return 2;
  } else if (page === 'settings') {
    return 3;
  }
};

const tabNumToPage = tabNum => {
  if (tabNum === 0) {
    return 'main';
  } else if (tabNum === 1) {
    return 'customurls';
  } else if (tabNum === 2) {
    return 'ignores';
  } else if (tabNum === 3) {
    return 'settings';
  }
};

const Menu = props => {
  return (
    <Tabs
      tabs={tabs}
      selected={pageToTabNum(props.page)}
      onSelect={selectedTab => props.pageSet(tabNumToPage(selectedTab))}
    />
  );
};

export default Menu;
