import * as React from 'react';
import {
  Card,
  FormLayout,
  Spinner,
  Stack,
  TextField,
  Icon,
  Button,
  ButtonGroup,
  Layout,
} from '@shopify/polaris';

import regexIsInvalid from '../utils/regexIsInvalid';
import RegexPatterns from '../utils/RegexPatterns';

const queryParams = window.location.search;
const shop = new URLSearchParams(queryParams).get('shop');

const IgnoresList = props => {
  if (props.initialLoad === true) {
    return (
      <Card title='Currently Ignored URLs' sectioned>
        <Stack distribution='center'>
          <Spinner color='teal' />
        </Stack>
      </Card>
    );
  } else if (props.ignores.length > 0) {
    return (
      <Card title='Currently Ignored URLs' sectioned>
        {props.ignores.map((ignore, index) => (
          <Ignore
            key={index}
            index={index}
            isLoading={props.isLoading}
            ignore={ignore}
            changeIgnore={props.changeIgnore}
            deleteIgnore={props.deleteIgnore}
            handleChange={props.handleChange}
          />
        ))}
      </Card>
    );
  } else {
    return (
      <Card title='Currently Ignored URLs' sectioned>
        <p>No existing ignored URLs found.</p>
      </Card>
    );
  }
};

const Ignore = props => {
  return (
    <FormLayout>
      <Stack alignment='trailing'>
        <Stack.Item fill>
          <p>{props.ignore}</p>
        </Stack.Item>
        <Stack.Item>
          <ButtonGroup segmented>
            <Button
              destructive
              disabled={props.isLoading}
              loading={props.isLoading}
              onClick={() => props.deleteIgnore(props.ignore)}
            >
              Unignore
            </Button>
          </ButtonGroup>
        </Stack.Item>
      </Stack>
    </FormLayout>
  );
};

export default class Ignores extends React.Component {
  state = {
    initialLoad: true,
    isLoading: false,
    ignores: [],
    ignoreUrlToAdd: '',
  };

  componentDidMount() {
    this.getIgnores();
  }

  getIgnores = () => {
    this.setState({ isLoading: true });
    fetch(`/api/ignores${queryParams}`)
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        console.log(data);
        this.setState({
          ignores: data,
          isLoading: false,
          initialLoad: false,
        });
      })
      .catch(error => {
        this.setState({ isLoading: false, initialLoad: false });
        //console.log(error);
        try {
          error.text().then(errorMessage => {
            //console.log(errorMessage);
            if (errorMessage.length > 0) {
              alert(errorMessage);
            }
          });
        } catch {}
      });
  };

  changeIgnore = url => {
    return new Promise((resolve, reject) => {
      let success = true;
      this.setState({ isLoading: true });
      let ignore = {};
      ignore.PartitionKey = shop;
      ignore.RowKey = url;

      console.log(ignore);
      fetch(`/api/ignores${queryParams}`, {
        method: 'POST',
        body: JSON.stringify(ignore),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          console.log(response);
          if (response.status >= 400 && response.status <= 500) {
            throw response;
          } else {
            this.getIgnores();
            resolve(success);
          }
        })
        .catch(error => {
          console.log(error);
          success = false;
          try {
            error.text().then(errorMessage => {
              //console.log(errorMessage);
              if (errorMessage.length > 0) {
                alert(errorMessage);
              }
            });
          } catch {}
          this.setState({ isLoading: false });
          resolve(success);
        });
    });
  };

  deleteIgnore = url => {
    this.setState({ isLoading: true });

    fetch(`/api/ignores/${encodeURIComponent(url)}${queryParams}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          this.getIgnores();
        }
      })
      .catch(error => {
        console.log(error);
        try {
          error.text().then(errorMessage => {
            console.log(errorMessage);
            if (errorMessage.length > 0) {
              alert(errorMessage);
            }
          });
        } catch {}
        this.setState({ isLoading: false });
      });
  };

  handleChange = (index, field) => newValue => {
    const newIgnores = this.state.ignores.map((item, iindex) => {
      if (index !== iindex) return item;
      return { ...item, [field]: newValue };
    });

    this.setState({ ignores: newIgnores });
  };

  render() {
    const { initialLoad, isLoading, ignores } = this.state;

    return (
      <Layout>
        <Layout.Section>
          <Card sectioned subdued>
            <p>
              Add a new URL to ignore when URL checking below, or stop ignoring
              an existing ignored URL in the table further below.
            </p>
          </Card>
          <Card title='Add New Ignored URL' sectioned>
            <FormLayout>
              <Stack alignment='leading'>
                <Stack.Item fill>
                  <TextField
                    label='Ignore This URL'
                    placeholder='https://domain.com/page'
                    value={this.state.ignoreUrlToAdd}
                    onChange={newValue =>
                      this.setState({ ignoreUrlToAdd: newValue })
                    }
                    pattern={RegexPatterns.url}
                    error={
                      regexIsInvalid(
                        this.state.ignoreUrlToAdd,
                        RegexPatterns.url,
                      )
                        ? 'Must be a valid URL, including protocol (https)'
                        : ''
                    }
                  />
                </Stack.Item>
              </Stack>
              <Stack distribution='trailing'>
                <Button
                  primary
                  size='large'
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => {
                    this.changeIgnore(this.state.ignoreUrlToAdd).then(
                      success => {
                        console.log(success);
                        if (success === true) {
                          this.setState({
                            ignoreUrlToAdd: '',
                          });
                        }
                      },
                    );
                  }}
                >
                  Add
                </Button>
              </Stack>
            </FormLayout>
          </Card>
          <IgnoresList
            ignores={ignores}
            initalLoad={initialLoad}
            isLoading={isLoading}
            changeIgnore={this.changeIgnore}
            deleteIgnore={this.deleteIgnore}
            handleChange={this.handleChange}
          />
        </Layout.Section>
      </Layout>
    );
  }
}
