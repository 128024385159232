import React from 'react';
import {
  Layout,
  Stack,
  Spinner,
  Card,
  FormLayout,
  TextField,
  PageActions,
  SettingToggle,
} from '@shopify/polaris';

const queryParams = window.location.search;
const shop = new URLSearchParams(queryParams).get('shop');

export default class Settings extends React.Component {
  state = {
    isLoading: true,
    settings: {},
    unsavedChanges: false,
  };

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    this.setState({ isLoading: true });
    fetch(`/api/settings${queryParams}`)
      .then(response => {
        if (response.status >= 400 && response.status <= 500) {
          throw response;
        } else {
          return response.json();
        }
      })
      .then(data => {
        console.log(data);
        this.setState({
          settings: data,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({ isLoading: false, initialLoad: false });
        //console.log(error);
        try {
          error.text().then(errorMessage => {
            //console.log(errorMessage);
            if (errorMessage.length > 0) {
              alert(errorMessage);
            }
          });
        } catch {}
      });
  };

  handleChange = (fieldName, newValue) => {
    let tempSettings = this.state.settings;
    tempSettings[fieldName] = newValue;
    this.setState({ settings: tempSettings, unsavedChanges: true });
  };

  changeSettings = () => {
    return new Promise((resolve, reject) => {
      let success = true;
      this.setState({ isLoading: true, unsavedChanges: false });

      fetch(`/api/settings${queryParams}`, {
        method: 'PUT',
        body: JSON.stringify(this.state.settings),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          console.log(response);
          if (response.status >= 400 && response.status <= 500) {
            throw response;
          } else {
            this.getSettings();
            resolve(success);
          }
        })
        .catch(error => {
          console.log(error);
          success = false;
          try {
            error.text().then(errorMessage => {
              //console.log(errorMessage);
              if (errorMessage.length > 0) {
                alert(errorMessage);
              }
            });
          } catch {}
          this.setState({ isLoading: false });
          resolve(success);
        });
    });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <Stack distribution='center'>
          <Spinner color='teal' />
        </Stack>
      );
    } else {
      return (
        <React.Fragment>
          <PageActions
            primaryAction={{
              content: 'Save',
              disabled: !this.state.unsavedChanges,
              onAction: () => this.changeSettings(),
            }}
          />
          <Layout>
            <Layout.AnnotatedSection
              title='Email Settings'
              description='Email notifications can be sent once a week, giving a digest of what type of incidents were caught in the past week.'
            >
              <Card sectioned>
                <FormLayout>
                  <SettingToggle
                    action={{
                      content:
                        this.state.settings.NotifyThroughEmail || false
                          ? 'Disable'
                          : 'Enable',
                      onAction: () =>
                        this.handleChange(
                          'NotifyThroughEmail',
                          !(this.state.settings.NotifyThroughEmail || false),
                        ),
                    }}
                    enabled={this.state.settings.NotifyThroughEmail || false}
                  >
                    Email Notifications are{' '}
                    <strong>
                      {this.state.settings.NotifyThroughEmail || false
                        ? 'Enabled'
                        : 'Disabled'}
                    </strong>
                  </SettingToggle>
                  <TextField
                    label='Email Address'
                    readOnly={
                      !(this.state.settings.NotifyThroughEmail || false)
                    }
                    placeholder='you@yourdomain.com'
                    type='email'
                    value={this.state.settings.NotificationEmail}
                    onChange={newValue =>
                      this.handleChange('NotificationEmail', newValue)
                    }
                  />
                </FormLayout>
              </Card>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title='Out of Stock Products Settings'
              description='URLs that are determined to be products can be checked for in stock status as well.'
            >
              <SettingToggle
                action={{
                  content:
                    this.state.settings.CheckForOOSProducts || false
                      ? 'Disable'
                      : 'Enable',
                  onAction: () =>
                    this.handleChange(
                      'CheckForOOSProducts',
                      !(this.state.settings.CheckForOOSProducts || false),
                    ),
                }}
                enabled={this.state.settings.CheckForOOSProducts || false}
              >
                Out of Stock Product Checking is{' '}
                <strong>
                  {this.state.settings.CheckForOOSProducts || false
                    ? 'Enabled'
                    : 'Disabled'}
                </strong>
              </SettingToggle>
            </Layout.AnnotatedSection>
            <Layout.AnnotatedSection
              title='Custom HTML Comment Parsing Settings'
              description='A special HTML Commenting pattern can be used to dynamically generate URLs, which are then checked.'
            >
              <SettingToggle
                action={{
                  content:
                    this.state.settings.CustomCommentParsing || false
                      ? 'Disable'
                      : 'Enable',
                  onAction: () =>
                    this.handleChange(
                      'CustomCommentParsing',
                      !(this.state.settings.CustomCommentParsing || false),
                    ),
                }}
                enabled={this.state.settings.CustomCommentParsing || false}
              >
                Custom HTML Comment parsing is{' '}
                <strong>
                  {this.state.settings.CustomCommentParsing || false
                    ? 'Enabled'
                    : 'Disabled'}
                </strong>
              </SettingToggle>
            </Layout.AnnotatedSection>
          </Layout>
        </React.Fragment>
      );
    }
  }
}
